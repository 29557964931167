import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  report: boolean=false;

  constructor() { }
  isAdminAccess;
  
  isProjectManager;
  isAccessable; 
  darMenuText;
  userModule;
  accountModule;
  projectModule;
  userLevel;
  leaveModule;
  dar;
  allModule=["user","account","project","dar","report",'leave'];
  loggedUser;
  ngOnInit(): void {
    this.userModule=false;
    this.isAccessable=false;
    this.isProjectManager=false;
    this.userModule=false;
    this.userLevel=false;
    this.accountModule=false;
    this.dar=false;
    this.projectModule=false;
    this.leaveModule=false;
    var loggedInUser= JSON.parse(localStorage.getItem("currentUser"))
    this.loggedUser=loggedInUser;
    this.permissionWiseAccess(loggedInUser.user.user[0].role[0].name)
  }

   permissionWiseAccess(userType){
        switch(userType){
          case "super-admin":
            this.isAdminAccess=true;
            this.darMenuText="Employee DAR List";
            let module1=this.loggedUser.user.user[0].modules;
            this.activatedModule(module1);
            break;
          case "admin":
            this.isProjectManager=true  
            this.darMenuText="Employee DAR List"
            let module=this.loggedUser.user.user[0].modules;
            this.activatedModule(module);
            
            break;
          case 'user':
            // this.isProjectManager=true  
            this.userLevel=true;
             this.darMenuText="My Dar"
             let modules=this.loggedUser.user.user[0].modules;
             this.activatedModule(modules);
        
          default :
            this.darMenuText="My Dar"
            console.log("123")  
        }
   }
   activatedModule(modules){
    
        modules.forEach(item=>{
          if(item.name=="user"){
           this.userModule=true;
          }else if(item.name.toLowerCase()=="projects"){
          this.projectModule=true;
          }else if(item.name.toLowerCase()=="account"){
            this.accountModule=true;
          }else if(item.name.toLowerCase()=="darlist"){
            this.dar=true;
          }else if(item.name.toLowerCase()=="reports"){
            this.report=true
         
          }else if(item.name.toLowerCase()=="leave"){
            this.leaveModule=true;
          }
      })
   }

}
