import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule} from '@angular/forms'
import { AppRoutingModule} from './app-routing.module';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { AppComponent } from './app.component';
import { DefaultComponent } from './layouts/default/default.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { ShareModuleModule } from './share-module/share-module.module';
import { ToastrModule } from "ng6-toastr-notifications";
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { httpInterceptProvider} from './share-module/http-interceptor'
import { TokenInterceptorService} from './share-module/http-interceptor/token-interceptor.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from "ngx-bootstrap/chronos";
import { enGbLocale } from "ngx-bootstrap/locale";
defineLocale("en-gb", enGbLocale);

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    MainLayoutComponent,
    
    
 ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ShareModuleModule,

    HttpClientModule,
    ToastrModule.forRoot(),
    LoadingBarRouterModule,
    BrowserAnimationsModule,
    NgbModule,
    BsDatepickerModule.forRoot(),
  ],
 
  providers: [{provide:HTTP_INTERCEPTORS,useClass:TokenInterceptorService,multi:true},BsLocaleService],
  bootstrap: [AppComponent]
})
export class AppModule { 
  /*constructor(private bsLocaleService: BsLocaleService) {
    //this.bsLocaleService.use('nb');
  }*/
}
